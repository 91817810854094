<template>
  <transition name="slide">
    <CCard>
      <CCardBody>
        <CButton color="primary" @click="create()"
          ><CIcon name="cil-plus" /> New Cancellation Policy</CButton
        >
        <br />
        <br />
        <br />

        <CAlert :color="alertType" v-if="message"> {{ message }}</CAlert>
        <CDataTable
          :items="items"
          :fields="fields"
          :items-per-page="50"
          hover
          pagination
        >
          <template #name="{ item }">
            <td>
              <strong>{{ item.key }}</strong>
            </td>
          </template>
          <template #Name="{ item }">
            <td style="width: 40%">
              {{ item.nameTranslations[0].content }}
            </td>
          </template>

          <template #EDIT="{ item }">
            <td style="width: 15%">
              <CButton color="primary" @click="edit(item.id)"
                ><CIcon name="cil-pencil" /> Edit</CButton
              >
            </td>
          </template>
          <template #DELETE="{ item }">
            <td style="width: 15%">
              <CButton
                color="danger"
                @click="
                  warningModal = true;
                  policy = item;
                "
                ><CIcon name="cil-trash" /> Delete</CButton
              >
            </td>
          </template>
        </CDataTable>

        <CModal
          v-model="policy.id"
          title="Are you sure to delete selected policy?"
          color="danger"
          :show.sync="warningModal"
          @update:show="closeModal"
        >
          <CRow>
            <CCol col="12">
              <CInput
                label="Key"
                type="input"
                v-model="policy.key"
                disabled
              />
            </CCol>
          </CRow>
        </CModal>
      </CCardBody>
    </CCard>
  </transition>
</template>

<script>
import axios from "axios";

export default {
  name: "CancellationPolicies",
  data: () => {
    return {
      token: localStorage.getItem("api_token"),
      partnerId: localStorage.getItem("partner_id"),
      emptyGuid: "00000000-0000-0000-0000-000000000000",
      policy: {
        id: "00000000-0000-0000-0000-000000000000",
        key: null,
        partnerName:null,
        contentTranslations: [],
      },

      warningModal: false,
      items: [],
      fields: ["key", "Name", "EDIT", "DELETE"],
      // Alert
      message: null,
      alertType: "danger",
    };
  },
  computed: {},
  methods: {
    closeModal(status, evt, accept) {
      if (accept) {
        this.delete(this.policy.id);
      }
    },
    edit(id) {
      const link = `cancellationpolicies/create/${id.toString()}`;
      this.$router.push({ path: link });
    },
    delete(id) {
      let self = this;
      axios
        .delete(`${this.$apiAdress}/v1/CancellationPolicy/${id}`)
        .then(function () {
                    self.alertType = "success";
          setTimeout(function () {
              self.message = null;
          }, 5000);
          self.message = "Successfully deleted policy.";
          self.getList();
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    create() {
      this.$router.push({ path: `cancellationpolicies/create/${this.emptyGuid}` });
    },

    getList() {
      let self = this;
      axios
        .get(this.$apiAdress + `/v1/CancellationPolicy`)
        .then(function (response) {
          self.items = response.data;
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },
  },
  mounted: function () {
    this.getList();
  },
};
</script>

<style scoped>
.card-body >>> table > tbody > tr > td {
  cursor: pointer;
}
</style>
